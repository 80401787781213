<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">
                Portals &amp; Extranets<br />
              </h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Bringing your external systems, processes and people together.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content columns">
      <div class="column is-size-4">
        <h1>
          We deliver <span>precise</span> &amp; <span>accurate</span> solutions
          that meaningfully improve processes and communications between our
          clients and their external stakeholders. <br /><br />A carefully
          designed &amp; maintained Extranet solution can significantly improve
          <span>productivity</span> by automating &amp; standardising routine
          processes, <span>removing bottlenecks</span> and
          <span>reducing delays.</span> <br /><br />
          Staff &amp; personnel will be able to access disparate applications
          through a single, consistent &amp; unified system. The business
          process would be monitored &amp; coordinated to a higher degree
          alongside accurate and flexible reporting. <br /><br />
          Our Extranet track record includes large organisations such as the
          <span>NSW</span> &amp; <span>WA State Governments</span>,
          <span>Toyota</span> &amp; <span>Lotterywest</span>.
        </h1>
      </div>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/solutions-banner/portals-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/solutions-banner/portals-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Portals-and-extranets"
}
</script>
